import React, { FC } from 'react';

import { randomHash } from './utils';
import { VerifySignInProps } from './types';

import './styles.scss';

const Inputs: FC<VerifySignInProps> = (props) =>
  <>
    {[...Array(props.number)].map((_, i) => {
      const { ref, ...rest } = props.register(`${i}-${randomHash}-verification-code`, { required: true });
      return (
        <input
          type="text"
          key={i}
          id={`verification-code-${i}`}
          className={`form-control text-center ${props.errors[`${i}-${randomHash}-verification-code`] ? 'form-error' : ''}`.trim()}
          onClick={props.handleOnClick}
          onInput={props.handleOnInput}
          onPaste={props.handleOnPaste}
          data-input-number={i}
          autoComplete="disable-autocomplete"
          autoFocus={i === 0}
          {...rest}
          ref={(e) => {
            ref(e);
            (props.inputEls.current as Record<number, HTMLInputElement>)[i] = e as HTMLInputElement;
          }}
        />
      );
    })}
  </>;

export default Inputs;
