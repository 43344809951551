export const abbreviate = (num: number, digits: number) => {
  const si = [
    { value: 1, symbol: '' },
    { value: 1E3, symbol: 'k' },
    { value: 1E6, symbol: 'M' },
    { value: 1E9, symbol: 'G' },
    { value: 1E12, symbol: 'T' },
    { value: 1E15, symbol: 'P' },
    { value: 1E18, symbol: 'E' }
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  let i;

  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) break;
  }

  return (num / si[i].value).toFixed(digits).replace(rx, '$1') + si[i].symbol;
};

//Function to convert a given number into words.
const stringNumber = (numb1: number, numb2: number, numb3: number) => {
  const unit = ['', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine'],
    tens = ['ten', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen'],
    decs = ['', 'ten', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];

  let numb1ToWords = '', numb2ToWords = '', numb3ToWords = '';

  numb1ToWords = numb1 !== 0 ? unit[numb1] + ' hundred ' : '';

  if (numb2 !== 0) {
    if (numb2 === 1) {
      numb2ToWords = tens[numb3];
      numb3ToWords = '';
    } else {
      numb2ToWords = decs[numb2];

      numb3ToWords = numb3 !== 0 ? '-' + unit[numb3] : '';
    }
  } else {
    numb2ToWords = '';

    numb3ToWords = numb3 !== 0 ? unit[numb3] : '';
  }

  return (numb1 + numb2 + numb3 === 0) ? '' : (numb1ToWords + numb2ToWords + numb3ToWords).trim();
};

const createString = (amountAsArray: string[]) => {
  let stringed = '', zeros = '';
  const times = amountAsArray.length;

  if (times <= 9) {
    for (let cntr = 9 - times; cntr !== 0; cntr--) { zeros += '0'; }

    const amountWithZero: string = zeros + amountAsArray.join('');
    const number: number[] = amountWithZero.split('').map((value: string) => parseInt(value, 10));

    const segments = [];

    segments.push(stringNumber(number[0], number[1], number[2]));
    segments.push((number[0] === 0 && number[1] === 0 && number[2] === 0) ? '' : 'million');
    segments.push(stringNumber(number[3], number[4], number[5]));
    segments.push((number[3] === 0 && number[4] === 0 && number[5] === 0) ? '' : 'thousand');
    segments.push(stringNumber(number[6], number[7], number[8]));

    stringed += segments.filter(Boolean).join(' ');
  } else {
    stringed = 'By now max number is 999,999,999';
  }

  return stringed;
};

export const inWords = (numberToConvert: string, currency: Record<string, any>) => {
  if(!numberToConvert) return;

  let integerWords, decimalWords;
  const result = [];
  const amount = numberToConvert.toString().replace(/[^(0-9).]/g, '').split('.');
  const isNegative = numberToConvert.toString().substring(0, 1) == '-';

  if (amount[0] && parseInt(amount[0], 10) > 0) {
    integerWords = createString(amount[0].split(''));
    result.push(integerWords + ' ' + currency.currencyunit.toLowerCase());
  }

  if (amount[1] && parseInt(amount[1], 10) > 0) {
    if (/^\d$/.test(amount[1])) amount[1] = amount[1] + '0';

    decimalWords = createString(amount[1].split(''));
    result.push(decimalWords + ' ' + currency.currencysubunit.toLowerCase());
  }

  return (isNegative ? 'minus ' : '') + result.join('<span> and </span>');
};
