import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Form, Card } from 'react-bootstrap';

import { InputFormGroup, SelectFormGroup, Checkbox } from 'bundles/components/common/Form';
import { isRequired, isSelectRequired, isUsedRegistrationNumber } from 'bundles/components/common/Form/validations';
import { URL } from 'bundles/constants';
import { Step } from 'bundles/components/common/Steps';
import Loader from 'bundles/components/common/Loader';
import { processFieldName } from 'bundles/utils';
import If from 'bundles/components/common/If';
import useMerchantRegistrationSecondStep from './hooks/useMerchantRegistrationSecondStep';
import { isRequiredBasedOn } from './utils';

const MerchantRegistrationFormSecondStep = () => {
  const { t, i18n } = useTranslation();
  const { register, errors, state, handleSubmitSignUp, handleChangeCompanyLegalStatus, handleChangeCurrency } = useMerchantRegistrationSecondStep();

  return (
    <Card>
      <Card.Header as={'h1'} className="border-0">{t('public.titles.business_account')}</Card.Header>

      <Card.Body>
        <Form className="js-no-disable-submit-form">
          <div className="form-section-title mt-0">{t('titles.business_details')}</div>
          <Row>
            <Col xl={4}>
              <SelectFormGroup
                name="merchant_registration[merchant_attributes][company_legal_status_id]"
                label={t('labels.legal_status')}
                register={register}
                onChange={(e: ChangeEvent<HTMLSelectElement>) => handleChangeCompanyLegalStatus(e.target.options[e.target.selectedIndex].innerHTML)}
                validate={{ isSelectRequired }}
                errors={errors}
                options={state.company_legal_statuses}
              />
            </Col>
            <Col xl={4}>
              <SelectFormGroup
                name="merchant_registration[merchant_attributes][company_industry_id]"
                label={t('labels.industry')}
                register={register}
                validate={{ isSelectRequired }}
                errors={errors}
                options={state.company_industries}
              />
            </Col>
            <Col xl={4}>
              <SelectFormGroup
                name="merchant_registration[merchant_attributes][company_products_category_id]"
                label={t('labels.category')}
                register={register}
                validate={{ isSelectRequired }}
                errors={errors}
                options={state.company_products_categories}
              />
            </Col>
            <Col xl={6}>
              <InputFormGroup
                name="merchant_registration[merchant_attributes][company_legal_name]"
                label={t('labels.trading_name')}
                register={register}
                validate={{ isRequired }}
                errors={errors}
                maxLength={100}
                autoComplete="off"
              />
            </Col>
            <Col xl={6}>
              <InputFormGroup
                name="merchant_registration[merchant_attributes][company_trading_name]"
                label={t('labels.legal_name')}
                register={register}
                validate={isRequiredBasedOn(state.currentCompanyLegalStatus) ? { isRequired } : {}}
                errors={errors}
                maxLength={100}
                autoComplete="off"
              />
            </Col>
            <Col xl={6}>
              <InputFormGroup
                name="merchant_registration[merchant_attributes][registration_number]"
                label={t('labels.registration_number')}
                register={register}
                validate={ { ...(!isRequiredBasedOn(state.currentCompanyLegalStatus) ? { isRequired } : {}), isUsedRegistrationNumber: (value: string) => isUsedRegistrationNumber(value, state.errorFields?.registration_number?.value, state.errorFields?.registration_number?.message) } }
                errors={errors}
                maxLength={250}
                autoComplete="off"
              />
            </Col>
            <Col xl={6}>
              <InputFormGroup
                name="merchant_registration[merchant_attributes][websites]"
                label={t('labels.websites')}
                register={register}
                validate={{ isRequired }}
                errors={errors}
                maxLength={255}
                autoComplete="off"
              />
            </Col>
          </Row>

          <div className="form-section-title">{t('labels.company_address')}</div>

          <Row>
            <Col xl={6}>
              <InputFormGroup
                label={t('labels.address_line_1')}
                name="merchant_registration[merchant_attributes][address_attributes][address_line_1]"
                autoComplete="off"
                register={register}
                validate={{ isRequired }}
                maxLength={100}
                errors={errors}
              />
            </Col>
            <Col xl={6}>
              <InputFormGroup
                label={`${t('labels.address_line_2')} <i class="optional">(${t('labels.optional')})</i>`}
                name="merchant_registration[merchant_attributes][address_attributes][address_line_2]"
                maxLength={100}
                autoComplete="off"
                register={register}
              />
            </Col>
            <Col xl={3}>
              <SelectFormGroup
                label={t('labels.country')}
                name="merchant_registration[merchant_attributes][address_attributes][country_id]]"
                register={register}
                options={state.countries}
                validate={{ isSelectRequired }}
                errors={errors}
              />
            </Col>
            <Col xl={3}>
              <InputFormGroup
                name="merchant_registration[merchant_attributes][address_attributes][state]"
                label={`${t('labels.state')} <i class="optional">(${t('labels.optional')})</i>`}
                minLength={1}
                maxLength={35}
                autoComplete="off"
                register={register}
              />
            </Col>
            <Col xl={3}>
              <InputFormGroup
                label={t('labels.city')}
                name="merchant_registration[merchant_attributes][address_attributes][city]"
                minLength={1}
                maxLength={35}
                autoComplete="off"
                register={register}
                validate={{ isRequired }}
                errors={errors}
              />
            </Col>
            <Col xl={3}>
              <InputFormGroup
                label={t('labels.postcode')}
                name="merchant_registration[merchant_attributes][address_attributes][postcode]"
                minLength={1}
                maxLength={11}
                autoComplete="off"
                register={register}
                validate={{ isRequired }}
                errors={errors}
              />
            </Col>
          </Row>
          <div className="form-section-title">{t('labels.currency')}<span className="text-danger">*</span></div>
          <Row>
            {state.currencies?.map((currency, i) =>
              <Col key={currency.id} sm={2}>
                <div className="custom-control custom-checkbox form-group">
                  <input
                    id={`merchant_registration[merchant_attributes][wallets_attributes][${i}][currency_id]`}
                    type="checkbox"
                    defaultValue={currency.id}
                    className={`custom-control-input ${processFieldName(errors, 'merchant_registration[merchant_attributes][wallets_attributes]') ? 'form-error' : ''}`.trim()}
                    {...register!(`merchant_registration[merchant_attributes][wallets_attributes][${i}][currency_id]`, { onChange: handleChangeCurrency })}
                  />

                  <label htmlFor={`merchant_registration[merchant_attributes][wallets_attributes][${i}][currency_id]`} className="custom-control-label">
                    {currency.code}
                  </label>
                </div>
              </Col>
            )}

            <If condition={(errors?.merchant_registration as any)?.merchant_attributes?.wallets_attributes}>
              <Col sm={12}>
                <Card.Text className="text-danger mb-4">{t('messages.errors.forms.required_checkboxes') as string}</Card.Text>
              </Col>
            </If>
          </Row>

          <Card.Text
            dangerouslySetInnerHTML={{
              __html: t('labels.accepted_terms_and_conditions', {
                terms_and_conditions_link: `/${i18n.language}${URL.BUSINESS_TERMS_AND_CONDITIONS}`,
                terms_of_use_link: `/${i18n.language}${URL.TERMS_OF_USE}`,
                privacy_policy_link: `/${i18n.language}${URL.PRIVACY_POLICY}`,
              }),
            }}
          />

          <Checkbox
            name="merchant_registration[merchant_attributes][subscribed_to_status_page_updates]"
            label={t('labels.subscribe_to_status_page')}
            register={register}
            classNameGroup="form-group"
            defaultValue={1}
          />

          <input type="hidden" {...register('step_number')} />

          <Step.Next onClickNext={handleSubmitSignUp} className="float-right btn-next">
            <Loader url={`${URL.MERCHANTS_REGISTRATIONS}/${state.merchant_registration_update.id}`} className={'text-center loading--over loading--with-fog'} />
            {t('labels.register')}
          </Step.Next>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default MerchantRegistrationFormSecondStep;
