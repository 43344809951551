import React, { SyntheticEvent, useState, FC } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { PhoneFormGroup } from 'bundles/components/common/Form';
import { isValidPhoneNumber } from 'bundles/components/common/Form/validations';
import { VerifyPhoneNumberViewProps } from './types';
import { URL } from 'bundles/constants';

const VerifyPhoneNumberForm: FC<VerifyPhoneNumberViewProps> = ({ phone_number }) => {
  const [isBtnDisabled, setIsbtnDisabled] = useState(false);
  const { register, handleSubmit, formState: { errors }, control } = useForm({ defaultValues: { 'phone_number': phone_number } });
  const { t, i18n } = useTranslation();

  const onSubmitForm = (fd: never, e: SyntheticEvent) => {
    setIsbtnDisabled(true);
    (e.target as HTMLFormElement).submit();
  };

  return (
    <form onSubmit={handleSubmit(onSubmitForm)} action={`/${i18n.language}${URL.SESSION_VERIFY}`} method="post" noValidate>
      <PhoneFormGroup
        name="phone_number"
        label={t('labels.phone_number')}
        register={register}
        control={control}
        pattern=".{7,20}"
        validate={{ isValidPhoneNumber }}
        autoFormat={false}
        errors={errors}
      />
      <input value={t('labels.verify')} className="btn btn-primary btn-block" disabled={isBtnDisabled} type="submit" />
    </form>);
};

export default VerifyPhoneNumberForm;
