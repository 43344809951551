import React, { useContext, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';

import { Steps } from 'bundles/components/common/Steps';
import MerchantRegistrationFormFirstStep from './MerchantRegistrationFormFirstStep';
import MerchantRegistrationFormSecondStep from './MerchantRegistrationFormSecondStep';
import MerchantRegistrationThirdStep from './MerchantRegistrationThirdStep';
import { MerchantRegistrationContext } from 'bundles/contexts/MerchantRegistrationContext/MerchantRegistrationContextProvider';
import axios from 'bundles/apis/defaults';
import { URL } from 'bundles/constants';

const MerchantRegistrationSteps = () => {
  const { dispatch } = useContext(MerchantRegistrationContext);

  useEffect(() => { axios.get(URL.MERCHANTS_REGISTRATIONS_NEW).then(r => dispatch({ type: 'GET_PAGE_DATA', payload: r.data })); }, []);

  return (
    <div className="register">
      <Row>
        <Col lg={7} className="col-centered">
          <Steps>
            <Steps.ProgressIndicator steps={3} variant="w-75" />
            <Steps.Step index={1}><MerchantRegistrationFormFirstStep /></Steps.Step>
            <Steps.Step index={2}><MerchantRegistrationFormSecondStep /></Steps.Step>
            <Steps.Step index={3}><MerchantRegistrationThirdStep /></Steps.Step>
          </Steps>
        </Col>
      </Row>
    </div>
  );
};

export default MerchantRegistrationSteps;
