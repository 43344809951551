import { i18n } from './index';

const buildSelectOptions = (arr: any[], value: string, title: string | string[], defaultOption = i18n.t('labels.all')) => {
  const optionsArr = [[defaultOption, '']];
  let title_processed = '';

  arr.forEach(item => {
    title_processed = Array.isArray(title) ? title.filter(t => Boolean(item[t])).join() : title;

    optionsArr.push([item[title_processed], item[value]]);
  });

  return optionsArr;
};

export default buildSelectOptions;
