import React, { FC, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { Steps } from 'bundles/components/common/Steps';
import ConsumerRegistrationForm from 'bundles/components/public/consumerRegistration/ConsumerRegistrationForm';
import If from 'bundles/components/common/If/index';
import { URL } from 'bundles/constants';
import { ViewPropsTypes } from 'bundles/components/consumer/accounts/types';

const ConsumerRegistrationView: FC<ViewPropsTypes>  = ({ inline_message }) => {
  const { t, i18n } = useTranslation();
  const [signUpResult, setSignUpResult] = useState<string>();

  const onSubmitSignUp = (response: string) => setSignUpResult(response);

  return (
    <div className="register">
      <Steps>
        <Steps.Step index={1}>
          <Row>
            <Col lg={7} className="col-centered">
              <If condition={inline_message}>
                <div dangerouslySetInnerHTML={{ __html: inline_message }} />
              </If>

              <Card>
                <Card.Header as={'h1'} className="border-0">{t('titles.personal_registration')}</Card.Header>
                <Card.Body>
                  <ConsumerRegistrationForm onSubmitSignUp={onSubmitSignUp} />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Steps.Step>

        <Steps.Step index={2}>
          <Row>
            <Col lg={7} className="col-centered text-center">
              <h1 className="border-0 justify-content-center h2 mb-4">{t(`titles.registration.${signUpResult}`)}</h1>
              <p className="text-center mb-5" dangerouslySetInnerHTML={{ __html: t(`messages.help.registration.${signUpResult}`, { link: URL.SESSION_NEW }) }} />
              <a href={`/${i18n.language}`} className="mb-5 back btn btn-primary">{t('links.return_to_homepage')}</a>
            </Col>
          </Row>
        </Steps.Step>
      </Steps>

      <p className="text-center">{t('messages.help.ip_address_being_recorded')}</p>
    </div>
  );
};

export default ConsumerRegistrationView;
