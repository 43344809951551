import React, { SyntheticEvent, useRef, useState, FC } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Inputs from './Inputs';
import { onEmpty_FocusFirstEmptyInput, pasteProcessing, inputProcessing } from './utils';
import { VerifySignInFormProps } from './types';

const VerifySignInForm: FC<VerifySignInFormProps> = (props) => {
  const { register, handleSubmit, formState: { errors }, clearErrors } = useForm();
  const [invalidCode, setInvalidCode] =  useState(false);
  const [clipboardData, setClipboardData] =  useState('');
  const [currentValue, setCurrentValue] =  useState('');
  const { t } = useTranslation();
  const inputEls = useRef<Record<number, HTMLInputElement>>({});

  const onSubmitForm = (fd: any, e: SyntheticEvent) => (e.target as HTMLFormElement).submit();
  const handleOnClick = (e: SyntheticEvent) => onEmpty_FocusFirstEmptyInput(e, inputEls);
  const handleOnPaste = (e: React.ClipboardEvent<HTMLInputElement>) => pasteProcessing(e, inputEls, setClipboardData, setCurrentValue, setInvalidCode, clearErrors);
  const handleOnInput = (e: SyntheticEvent) => inputProcessing(e, inputEls, clipboardData, setClipboardData, currentValue, setInvalidCode, clearErrors);

  return (
    <form onSubmit={handleSubmit(onSubmitForm)} action={props.verify_url} method="post" noValidate>
      <div className="form-group">
        <label className="required">{t('labels.verification_code')}</label>
        <div className="flex justify-content-between code-inputs">
          <Inputs
            register={register}
            errors={errors}
            handleOnInput={handleOnInput}
            handleOnClick={handleOnClick}
            handleOnPaste={handleOnPaste}
            number={6}
            inputEls={inputEls} />
        </div>
        {Object.keys(errors).length ? <p className="form-error form-error--alt">{t('messages.errors.forms.fields_required')}</p> : null}
        {invalidCode && !Object.keys(errors).length ? <p className="form-error form-error--alt">{t('messages.errors.forms.invalid_code')}</p> : null}
      </div>

      <input value={t('labels.verify')} className="btn btn-primary btn-block" type="submit" />
      <div className="text-center mt-4 d-block">
        <a className="resendOtpCode resend-code mt-4 text-secondary" href={props.resend_code_url}>{t(`links.resend_otp_code${props.otp_channel === 'email' ?'_via_email':''}`)}</a>
      </div>
    </form>);
};

export default VerifySignInForm;
