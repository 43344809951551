const cSymbol = (currency: string | undefined, locale = 'en-US') =>
  currency ? (0).toLocaleString(
    locale,
    {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }
  ).replace(/\d/g, '').trim()
    : '';

export default cSymbol;
