// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.haml
declare var _ : any;

import 'core-js/stable'
import 'regenerator-runtime/runtime'

import $ from 'jquery';
import Spinner from 'spin';
import 'html5shiv';
import 'jquery.spin';

import 'validation-additional-methods';
import 'jquery-ui';
import 'jqueryUjs';

import './bundles/utils/polyfills/padEnd.js'

import 'bootstrap'

window.$ = window.jQuery = $;
window._ = _;
window.Spinner = Spinner;

document.documentElement.classList.remove('no-js');
