import React, { FC } from 'react';
import { Card } from 'react-bootstrap';

import { i18n } from 'bundles/utils';
import VerifySignInForm from 'bundles/components/public/verifySignIn/VerifySignInForm';
import { VerifySignInViewProps } from 'bundles/components/public/verifySignIn/types';
import If from 'bundles/components/common/If';

const VerifySignInView: FC<VerifySignInViewProps> = (props) =>
  <div className="sign-in">
    <If condition={props.inline_message}>
      <div className="d-flex justify-content-center" dangerouslySetInnerHTML={{ __html: props.inline_message || '' }} />
    </If>

    <Card>
      <Card.Header as={'h1'} className="border-0">{i18n.t('labels.verify_your_login')}</Card.Header>
      <Card.Body>
        <VerifySignInForm {...props} />
      </Card.Body>
    </Card>
  </div>;

export default VerifySignInView;
